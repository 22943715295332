<template>
  <metainfo />

  <!-- 배경 영상 -->
  <div id="app">
    <div class="video-container">
      <video src="@/assets/video/main.mp4" autoplay muted loop playsinline></video>
    </div>
    <div class="overlay">
      <div class="text">
        <div class="top animate__animated animate__fadeInUp" style="animation-delay: 0.2s;">KUNICORN INVESTMENT</div>
        <div class="main animate__animated animate__fadeInUp" style="animation-delay: 0.4s;">We Unlock the Cycle of Innovation and Empower K-Startups to Grow</div>
        <div class="line animate__animated animate__fadeInUp" style="animation-delay: 0.6s;"></div>
        <div class="description animate__animated animate__fadeInUp" style="animation-delay: 0.8s;">
          Kunicorn Investment is a venture capital and private equity investment firm trusting pre-unicorn startups with time and resources necessary for innovation. We leverage the combined experience and expertise of our staff and partners to selectively invest in hidden opportunities across various industries. We empower young promising startups to achieve their full potential via our members’ expertise and collaboration with valuable partners, promoting startup growth by identifying and maximizing their innovative potential as collaborators.
        </div>
      </div>
      <div class="bg"></div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'HomeView',
  mounted() {
    // 해당 라우터에 진입할 때 footer를 숨김
    this.$root.showFooter = false;
  },
  beforeUnmount() {
    // 해당 라우터를 떠날 때 footer를 다시 표시
    this.$root.showFooter = true;
  },
  setup () {
    useMeta({
      title: 'Home - Kunicorn Investment',
      htmlAttrs: { lang: 'ko', amp: true }
    })
  }
}
</script>

<style lang="scss" scoped>

// 반응형 미디어쿼리
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

#app {
  display: flex;
  justify-content: center;
  align-items: top;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  @media (max-width: 767px) {
    padding-top: 177.77%;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-self: center;
  align-items: center;
  .text {
    padding-inline: 30px;
    text-align: center;
    z-index: 2;
    max-width: 720px;
    margin: 0 auto;
    text-align: left;
    word-break: keep-all;
    .top {
      font-size: 14px;
      padding-bottom: 10px;
    }
    .main {
      font-size: 50px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .description {
      font-family: 'Heebo', sans-serif;
      font-size: 14px;
    }
    .line {
      border-top: 1px solid rgba(255,255,255,0.5);
      margin-block: 30px;
      height: 5px;
    }
  }
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;  
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
  }
}
@media (max-width: $xs) {
  .overlay {
    .text {
      .main {
        font-size: 30px;
        line-height: 1.2;
      }
      .top,.description {
        font-size: 12px;
      }
    }
  }
}
@media (min-width: $xs) and (max-width: $md) {
  .overlay {
    .text {
      .main {
        font-size: 40px;
      }
      .top,.description {
        font-size: 14px;
      }
    }
  }
}
</style>