import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home.vue'

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/about', name: 'about', component: () => import(/* webpackChunkName: "about" */ '../views/about.vue') },
  { path: '/leadership', name: 'leadership', component: () => import(/* webpackChunkName: "leadership" */ '../views/leadership.vue') },
  { path: '/portfolio', name: 'portfolio', component: () => import(/* webpackChunkName: "portfolio" */ '../views/portfolio.vue') },
  { path: '/notice', name: 'notice', component: () => import(/* webpackChunkName: "notice" */ '../views/notice.vue') },
  { path: '/news', name: 'news', component: () => import(/* webpackChunkName: "news" */ '../views/news.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0, behavior: 'auto' };
    }
  }
})

export default router
