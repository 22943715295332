<template>
  <metainfo />

  <!-- 레이아웃: 헤더 -->
  <header :class="{ scrolled: isScrolled }">
    <div class="logo">
      <router-link to="/">
        <img :src="require('@/assets/images/logo-white.png')" alt="">
      </router-link>
    </div>
    <nav>
      <router-link to="/">HOME</router-link>
      <router-link to="/about">ABOUT US</router-link>
      <router-link to="/leadership">LEADERSHIP</router-link>
      <router-link to="/portfolio">PORTFOLIO</router-link>
      <router-link to="/news">NEWS</router-link>
      <router-link to="/notice">NOTICE</router-link>
    </nav>
    <div class="side">
      <button @click="this.showMenu = true;" class="menu-icon-open">
        <i class="las la-align-justify"></i>
      </button>
      <div class="layer-backdrop" :class="this.showMenu ? 'show' : 'hide'" @click="this.showMenu = false;"></div>
      <div class="layer-sidemenu" :class="this.showMenu ? 'show' : 'hide'">
        <div class="menu-top">
          <button @click="this.showMenu = false;" class="menu-icon">
            <i class="las la-times"></i>
          </button>
        </div>
        <div class="logo">
          <img :src="require('@/assets/images/logo-white.png')" alt="">
        </div>
        <div class="menu-mobile">
          <nav>
            <router-link to="/" v-on:click="showMenuClose()">HOME</router-link>
            <router-link to="/about" v-on:click="showMenuClose()">ABOUT US</router-link>
            <router-link to="/leadership" v-on:click="showMenuClose()">LEADERSHIP</router-link>
            <router-link to="/portfolio" v-on:click="showMenuClose()">PORTFOLIO</router-link>
            <router-link to="/news" v-on:click="showMenuClose()">NEWS</router-link>
            <router-link to="/notice" v-on:click="showMenuClose()">NOTICE</router-link>
          </nav>
        </div>
        <div class="menu-desktop">
          <div class="text">
            <div class="title">Kunicorn Investment</div>
            <div class="description">
              (06301) 4th floor of Deokyoon Building,<br />
              Nonhyeon-ro 28-gil 45, Gangnam-gu,<br />
              Seoul, South Korea<br />
              info@koreaunicorn.com<br />
              (+82) 02-538-0887
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- 레이아웃: 본문 -->
  <router-view/>

  <!-- 레이아웃: 푸터 -->
  <footer v-if="this.showFooter">
    <div class="logo">
      <img :src="require('@/assets/images/logo-white.png')" alt="">
    </div>
    <nav>
      <router-link to="/">HOME</router-link>
      <router-link to="/about">ABOUT US</router-link>
      <router-link to="/leadership">LEADERSHIP</router-link>
      <router-link to="/portfolio">PORTFOLIO</router-link>
      <router-link to="/news">NEWS</router-link>
      <router-link to="/notice">NOTICE</router-link>
    </nav>
    <div>
      For more information, please contact <a href="mailto:info@koreaunicorn.com">info@koreaunicorn.com</a><br />
      ⓒ {{ new Date().getFullYear() }} Kunicorn Investment. All rights are reserved.
    </div>
  </footer>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  data() {
    return {
      showMenu: false,
      isScrolled: false,
      showFooter: true
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener("keydown", this.closeMenuOnEscape);
    window.addEventListener("scroll", this.closeMenuOnScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener("keydown", this.closeMenuOnEscape);
    window.removeEventListener("scroll", this.closeMenuOnScroll);
  },
  methods: {
    showMenuClose() {
      this.showMenu = false;
    },
    closeMenuOnEscape(event) {
      if (event.key === "Escape" && this.showMenu) {
        this.showMenu = false;
      }
    },
    closeMenuOnScroll() {
      if (this.showMenu) {
        this.showMenu = false;
      }
    },
    handleScroll() {
      if (window.scrollY > 1) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    }
  },
  setup () {
    useMeta({
      title: 'Kunicorn Investment',
      htmlAttrs: { lang: 'ko', amp: true }
    })
  }
}
</script>

<style lang="scss">

// 반응형 미디어쿼리
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  transition: background-color 0.2s ease;
  z-index: 1000;
  border-bottom: 1px solid rgba(255,255,255,0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 30px;
  &.scrolled {
    height: 50px;
    transition: height 0.2s ease;
    background-color: #000;
    >nav,.side .menu-layer .menu .menu-top {
      height: 50px;
    }
  }
  .logo {
    width: 100px;
    img {
      height: 32px;
    }
  }
  .side {
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .menu-icon-open {
      background-color: transparent;
      border: none;
      padding: 0;
      line-height: 1;
      i {
        color: rgba(255,255,255,0.7);
        font-size: 32px;
        transition: color 0.5s ease;
        &:hover {
          transition: color 0.5s ease;
          color: rgba(255,255,255,1);
        }
      }
    }
  }
  >nav {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      font-size: 13px;
      padding-inline: 20px;
      color: rgba(255,255,255,0.6);
      text-decoration: none;
      &.router-link-active,
      &.router-link-exact-active {
        color: rgba(255,255,255,1);
      }
    }
  }
  @media (max-width: $md) {
    >nav {
      display: none;
    }
  }
}
footer {
  border-top: 1px solid rgba(255,255,255,0.3);
  padding-top: 100px;
  padding-bottom: 120px;
  line-height: 1.6;
  font-size: 12px;
  color: #c1c1c1;
  font-family: 'Heebo', sans-serif;
  color: rgba(255,255,255,0.6);
  .logo {
    img {
      width: 160px;
    }
  }
  >nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 10px;
    a {
      padding-inline: 5px;
      color: rgba(255,255,255,0.6);
      text-decoration: none;
      &.router-link-active,
      &.router-link-exact-active {
        color: rgba(255,255,255,0.6);
      }
    }
  }
}
.layer-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255,255,255,0.3);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.show {
    width: 100%;
  }
  &.hide {
    width: 0;
  }
}
.layer-sidemenu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 320px;
  height: 100%;
  background-color: #000;
  z-index: 100;
  color: #fff;
  transition: right 0.3s ease;
  border-left: 1px solid rgba(255,255,255,0.3);
  padding-inline: 30px;
  text-align: left;
  &.show {
    right: 0;
    transition: right 0.3s ease;
  }
  &.hide {
    right: -320px;
  }
  .menu-top {
    height: 70px;
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .menu-icon {
      background-color: transparent;
      border: none;
      padding: 0;
      line-height: 1;
      i {
        color: rgba(255,255,255,0.7);
        font-size: 32px;
        transition: color 0.5s ease;
        &:hover {
          transition: color 0.5s ease;
          color: rgba(255,255,255,1);
        }
      }
    }
  }
  .logo {
    padding-block: 20px;
    img {
      height: 50px;
    }
  }
  .menu-desktop {
    margin-top: 40px;
    border-top: 1px solid rgba(255,255,255,0.3);
    padding-top: 40px;
    .text {
      .title {
        font-size: 20px;
        padding-top: 0px;
        padding-bottom: 15px;
      }
      .description {
        word-break: keep-all;
        font-size: 14px;
        line-height: 1.6;
        color: #c1c1c1;
        font-family: 'Heebo', sans-serif;
        padding-bottom: 30px;
      }
    }
  }
  .menu-mobile {
    margin-top: 40px;
    border-top: 1px solid rgba(255,255,255,0.3);
    padding-top: 40px;
    a {
      display: block;
      padding-block: 5px;
      color: rgba(255,255,255,0.6);
      text-decoration: none;
      &.router-link-active,
      &.router-link-exact-active {
        color: rgba(255,255,255,1);
      }
    }
  }
}
</style>