import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// 전역 SCSS 설정
require('@/assets/scss/app.scss')

// Bootstrap 설치
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// Animate.css 설치
import 'animate.css'

// MDI 아이콘 라이브러리 설치
// import '@mdi/font/css/materialdesignicons.min.css'

// line-awesome 아이콘 라이브러리 설치
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'

// vue-meta 설치
import { createMetaManager } from 'vue-meta'

createApp(App)
  .use(router)
  .use(createMetaManager())
  .mount('#app')